import React, { useEffect, useState } from 'react';
import MultipleQrScanner from './MultipleQrScanner';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteScanData,
  selectScannedProducts,
  clearScannedProducts,
} from '../../state/multipleInspectionSlice';
import {
  Card,
  Typography,
  Box,
  ListItem,
  List,
  IconButton,
  Modal,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  TextField,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DangerousIcon from '@mui/icons-material/Dangerous';
import { InspectorQR, ReasonsAPI } from '../../http/server-apis';
import toast from 'react-hot-toast';

const MultipleInspection = () => {
  const { InspectorID, DeviceInfoId } = useSelector((state) => state.User);
  const scannedProducts = useSelector(selectScannedProducts);
  const [error, setError] = useState('');
  const [refresh, setRefresh] = useState(true);
  const [reasonsList, setReasonsList] = useState([]);
  const [isDefectiveModalOpen, setIsDefectiveModalOpen] = useState(false);
  const [selectedDefectiveReason, setSelectedDefectiveReason] = useState('');
  const [selectedDefectReason, setSelectedDefectReason] = useState('');
  const [selectedDefectReasonID, setSelectedDefectReasonID] = useState('');
  const [isDisable, setIsDisable] = useState(true);
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [scanQr, setScanQr] = useState(true);
  const [qrData, setQrData] = useState([]);
  const [showData, setShowData] = useState(false);
  const dispatch = useDispatch();
  const [customReason, setCustomReason] = useState('');
  const [isClicked, setIsClicked] = useState({
    checkAgain: false,
    defected: false,
    customReason: false,
  });

  const handleDelete = (index) => {
    dispatch(deleteScanData(index));
  };
  useEffect(() => {}, [scannedProducts]);

  async function fetchReasonsList() {
    try {
      const res = await ReasonsAPI('get');
      setReasonsList(res?.data);
    } catch (err) {
      console.error('Error fetching reasons list:', err);
    }
  }

  const handleDefectiveButtonClick = () => {
    setIsDefectiveModalOpen(true);
  };

  const handleCloseDefectiveModal = () => {
    setIsDefectiveModalOpen(false);
  };

  useEffect(() => {
    fetchReasonsList();
    setRefresh(true);
  }, [refresh]);

  const handleCustomReasonChange = (event) => {
    setCustomReason(event.target.value);
  };
  const handleRadioChange = (event, id, reasonName) => {
    setSelectedDefectReason(reasonName);
    setSelectedDefectReasonID(id);
    setCustomReason('');
  };

  async function onUpdateStatusHandler2(status) {
    setIsLoading(true);

    try {
      for (const scannedProduct of scannedProducts) {
        const response = await InspectorQR('post', {
          data: {
            ID: Number(DeviceInfoId),
            Status: status,
            GUID: scannedProduct,
            InspectorID: InspectorID,
            ReasonID: selectedDefectReasonID,
            OtherReason: customReason,
            ReasonName: selectedDefectReason,
          },
        });

        const data = response?.data;

        if (data.status === 'success') {
          toast.success(`Successfully sent ${scannedProduct}`);
          setError('');
        } else {
          toast.error(`Failed to send ${scannedProduct}`);
          setError('');
        }
      }

      dispatch(clearScannedProducts());
      setIsDisable(true);
      setData(null);
      setQrData({});
      setScanQr(true);
      setShowData(false);
      setIsClicked({
        defected: false,
        checkAgain: false,
        customReason: false,
      });
      setSelectedDefectReason('');
      setCustomReason('');
    } catch (error) {
      toast.error(`Server error:${error.response.data.message}`);
    } finally {
      setIsLoading(false);
    }
  }

  async function onCheckStatusHandler(status) {
    setIsLoading(true);

    try {
      for (const scannedProduct of scannedProducts) {
        if (status === 0) {
          const response = await InspectorQR('post', {
            data: {
              ID: Number(DeviceInfoId),
              Status: status,
              GUID: scannedProduct,
              InspectorID: InspectorID,
              ReasonID: selectedDefectReasonID,
              OtherReason: customReason,
              ReasonName: selectedDefectReason,
            },
          });

          const data = response?.data;

          if (data.status === 'success') {
            toast.success(`Successfully sent ${scannedProduct}`);
            setError('');
          } else {
            toast.error(`Failed to send ${scannedProduct}`);
            setError('');
          }
        }
        if (status === 1) {
          const response = await InspectorQR('post', {
            data: {
              ID: Number(DeviceInfoId),
              Status: status,
              GUID: scannedProduct,
              InspectorID: InspectorID,
            },
          });

          const data = response?.data;

          if (data.status === 'success') {
            toast.success(`Successfully sent ${scannedProduct}`);
            setError('');
          } else {
            toast.error(`${response.data.message}`);
            setError('');
          }
        }
      }

      dispatch(clearScannedProducts());
      setSelectedDefectReason('');
      setCustomReason('');
      setScanQr(true);
      setIsDisable(true);
      setData(null);
      setQrData([]);
      setShowData(false);
      setIsClicked({
        defected: false,
        checkAgain: false,
        customReason: false,
      });
    } catch (error) {
      toast.error(`Server error: ${error.response.data.message}`);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div>
      <MultipleQrScanner />

      <Box mt={4}>
        <Typography
          variant='h3'
          color='#000'
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '1.2rem',
            fontFamily: 'Lato, sans-serif',
            fontWeight: 800,
          }}
        >
          {error}
        </Typography>
        {scannedProducts.length === 0 ? (
          <Box>
            <Typography
              variant='h3'
              color='#000'
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '1.2rem',
                fontFamily: 'Lato, sans-serif',
                fontWeight: 800,
              }}
            >
              There is No Scan Products
            </Typography>
          </Box>
        ) : (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <Typography
                variant='p'
                color='#9e9e9e'
                sx={{
                  flexBasis: '40%',
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '1rem',
                  fontFamily: 'Lato, sans-serif',
                  fontWeight: 500,
                  marginLeft: '8px',
                }}
              >
                Product Details
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexBasis: '50%',
                  marginTop: { xs: 2, sm: 0 },
                }}
              >
                <Box
                  sx={{
                    marginRight: 3,
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: { xs: 2, sm: 0 },
                  }}
                  onClick={() => onCheckStatusHandler(1)}
                >
                  <CheckCircleIcon
                    style={{ color: 'green', marginRight: '5px' }}
                  />
                  <input
                    type='radio'
                    id={`checkOk`}
                    name={`status`}
                    style={{ width: '18px', height: '18px' }}
                  />
                  <label htmlFor={`checkOk`}></label>
                </Box>
                <Box
                  sx={{
                    marginRight: 2,
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: { xs: 2, sm: 0 },
                  }}
                >
                  <DangerousIcon style={{ color: 'red', marginRight: '5px' }} />
                  <input
                    type='radio'
                    id={`defective`}
                    name={`status`}
                    onClick={() => handleDefectiveButtonClick()}
                    style={{ width: '18px', height: '18px' }}
                  />
                  <label htmlFor={`defective`}></label>
                </Box>
              </Box>
            </Box>
            <Modal
              open={isDefectiveModalOpen}
              onClose={handleCloseDefectiveModal}
              aria-labelledby='defective-modal-title'
              aria-describedby='defective-modal-description'
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box
                sx={{
                  backgroundColor: 'white',
                  border: '2px solid #FF9800',
                  borderRadius: '10px',
                  boxShadow: 24,
                  p: 4,
                }}
              >
                <Typography variant='h6'>Reasons of Defective</Typography>
                <FormControl component='fieldset'>
                  <RadioGroup
                    aria-label='defective-reason'
                    name='defective-reason'
                    value={selectedDefectiveReason}
                    onChange={(e) => {
                      setSelectedDefectiveReason(e.target.value);
                    }}
                  >
                    {reasonsList.map((data) => (
                      <div key={data.ReasonID}>
                        <FormControl>
                          <RadioGroup
                            aria-labelledby='demo-radio-buttons-group-label'
                            value={selectedDefectReasonID}
                            onChange={(e) =>
                              handleRadioChange(e, data.ReasonID, data.Reason)
                            }
                          >
                            <FormControlLabel
                              value={data.ReasonID}
                              control={<Radio />}
                              label={data.Reason}
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    ))}
                    {selectedDefectReasonID == 6 && (
                      <TextField
                        label='Other Reason'
                        variant='outlined'
                        sx={{
                          width: '100%',
                          marginTop: '15px',
                        }}
                        fullWidth
                        value={customReason}
                        onChange={handleCustomReasonChange}
                        error={isClicked.customReason}
                        helperText={isClicked.customReason ? 'mandatory' : null}
                      />
                    )}
                  </RadioGroup>
                </FormControl>
                <Button
                  sx={{
                    padding: '8px 32px',
                    fontSize: '1rem',
                    fontWeight: '600',
                    fontFamily: 'Lato, sans-serif',
                    backgroundColor: '#333',
                    color: '#fff !important',
                    textTransform: 'capitalize',
                    marginTop: '15px',
                    ':disabled': {
                      backgroundColor: '#ccc',
                      cursor: 'not-allowed',
                    },
                  }}
                  disabled={
                    (selectedDefectReason === 'Others' &&
                      customReason?.length === 0) ||
                    !selectedDefectReasonID > 0
                  }
                  onClick={() => {
                    onUpdateStatusHandler2(0);
                    if (selectedDefectReason.length === 0) {
                      setIsClicked({
                        defected: true,
                        checkAgain: false,
                        customReason: false,
                      });
                    } else if (
                      selectedDefectReason.length > 0 &&
                      customReason.length === 0
                    ) {
                      setIsClicked({
                        defected: false,
                        checkAgain: false,
                        customReason: true,
                      });
                    } else {
                      setIsClicked({
                        defected: false,
                        checkAgain: false,
                        customReason: false,
                      });
                    }
                  }}
                >
                  Defective
                </Button>
              </Box>
            </Modal>
          </>
        )}

        {scannedProducts?.map((product, index) => (
          <Card
            key={index}
            sx={{
              border: '1px solid #ccc',
              borderBottom: '3px solid #fec107',
              marginBottom: '30px',
              boxShadow: 'none',
              borderRadius: '10px',
            }}
          >
            <List>
              <ListItem
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant='p'
                  color='#9e9e9e'
                  sx={{
                    flexBasis: '35%',
                    fontSize: '0.8rem',
                    fontFamily: 'Lato, sans-serif',
                    fontWeight: 400,
                  }}
                >
                  GUID
                </Typography>
                <Typography
                  variant='p'
                  color='#9e9e9e'
                  sx={{
                    flexBasis: '35%',
                    fontSize: '0.8rem',
                    fontFamily: 'Lato, sans-serif',
                    fontWeight: 400,
                  }}
                >
                  :
                </Typography>

                <Typography
                  variant='p'
                  sx={{
                    flexBasis: '50%',
                    fontSize: '0.9rem',
                    fontFamily: 'Lato, sans-serif',
                    fontWeight: 600,
                  }}
                >
                  {product}
                </Typography>

                <Box
                  sx={{
                    display: 'flex',
                    flexBasis: '50%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: 'auto',
                  }}
                >
                  <IconButton
                    style={{ color: 'red' }}
                    aria-label='delete'
                    onClick={() => handleDelete(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </ListItem>
            </List>
          </Card>
        ))}
      </Box>
    </div>
  );
};

export default MultipleInspection;
