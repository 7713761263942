import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import { FaClipboardList } from 'react-icons/fa';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { sliderMenuList } from '../constants/Constants';
import { Link, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { Logout } from '../state/User';
import mainLogo from '../assets/images/logo.png';

import {
  BiBarcodeReader,
  BiChevronsRight,
  BiGroup,
  BiLogIn,
  BiLogOut,
  BiUserCircle,
} from 'react-icons/bi';
import { Grid } from '@mui/material';
import toast from 'react-hot-toast';
import { setAutoClear } from '../state/StoreGuid';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function BurgerMenu() {
  const Navigate = useNavigate();

  const dispatch = useDispatch();
  const { Login, Username } = useSelector((state) => state.User);
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    const handleBackButton = (event) => {
      if (event.state === null) {
        Navigate('/');
      }
    };

    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [Navigate]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  function onNavigateHandler(link) {
    Navigate(link);
    handleDrawerClose();
  }

  function onLogoutHandler() {
    handleDrawerClose();
    dispatch(Logout());
    dispatch(setAutoClear());
    Navigate('/');
  }

  function handleClick() {
    toast('This Feature Under Maintenance', {
      style: {
        borderRadius: '10px',
        background: '#333',
        color: '#fff',
      },
    });
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position='fixed' open={open} sx={{ backgroundColor: '#fec107' }}>
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box display='flex'>
            <IconButton
              aria-label='open drawer'
              onClick={handleDrawerOpen}
              edge='start'
              sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
            <Link to='/'>
              <Typography
                variant='h6'
                noWrap
                component='div'
                sx={{ display: 'flex', height: '38px' }}
              >
                <img src={mainLogo} alt='Udyogi-Logo' />
              </Typography>
            </Link>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant='temporary'
        anchor='left'
        open={open}
        onClose={handleDrawerClose}
      >
        <DrawerHeader
          sx={{
            backgroundColor: '#ffe38c',
          }}
        >
          {Username ? (
            <Grid container>
              <Grid
                item
                md={2}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <BiUserCircle fontSize={30} color='#333' />
              </Grid>

              <Grid
                item
                md={10}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <Typography variant='h6' pl={3}>
                  {Username}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              <Grid
                item
                md={2}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <BiUserCircle fontSize={30} color='#333' />
              </Grid>
              <Link
                to='/inspection-login'
                style={{
                  textDecoration: 'none',
                  listStyle: 'none',
                  color: '#000',
                }}
              >
                <Grid
                  item
                  md={10}
                  onClick={handleDrawerClose}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <Typography variant='h6' pl={3} textAlign={'center'}>
                    Login
                  </Typography>
                </Grid>
              </Link>
            </Grid>
          )}

          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {!Login &&
            sliderMenuList.map((s, index) => (
              <div key={index}>
                <ListItem
                  sx={{
                    borderBottom: '1px dashed #ccc',
                  }}
                  disablePadding
                  onClick={() => {
                    Navigate('/');
                    handleDrawerClose();
                  }}
                >
                  <ListItemButton
                    sx={{
                      textAlign: 'left',
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: '34px',
                      }}
                    >
                      <BiBarcodeReader fontSize={20} color='#ccc' />
                    </ListItemIcon>
                    <ListItemText primary='Scan QR' />

                    <ListItemIcon
                      sx={{
                        display: 'flex',
                        justifyContent: 'end',
                        color: '#ccc',
                        fontWeight: '500',
                      }}
                    >
                      <BiChevronsRight fontSize={20} />
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
                <ListItem
                  onClick={() => onNavigateHandler(s.link)}
                  sx={{
                    borderBottom: '1px dashed #ccc',
                  }}
                  disablePadding
                >
                  <ListItemButton
                    sx={{
                      textAlign: 'left',
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: '34px',
                      }}
                    >
                      <BiLogIn fontSize={20} color='#ccc' />
                    </ListItemIcon>
                    <ListItemText primary={s.name} />

                    <ListItemIcon
                      sx={{
                        display: 'flex',
                        justifyContent: 'end',
                        color: '#ccc',
                        fontWeight: '500',
                      }}
                    >
                      <BiChevronsRight fontSize={20} />
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
                <ListItem
                  sx={{
                    borderBottom: '1px dashed #ccc',
                  }}
                  disablePadding
                  onClick={() => {
                    Navigate('/contact-us');
                    handleDrawerClose();
                  }}
                >
                  <ListItemButton
                    sx={{
                      textAlign: 'left',
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: '34px',
                      }}
                    >
                      <BiGroup fontSize={20} color='#ccc' />
                    </ListItemIcon>
                    <ListItemText primary='Contact Us' />

                    <ListItemIcon
                      sx={{
                        display: 'flex',
                        justifyContent: 'end',
                        color: '#ccc',
                        fontWeight: '500',
                      }}
                    >
                      <BiChevronsRight fontSize={20} />
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
              </div>
            ))}

          {Login ? (
            <>
              <ListItem
                sx={{
                  borderBottom: '1px dashed #ccc',
                }}
                disablePadding
                onClick={() => {
                  Navigate('/');
                  handleDrawerClose();
                }}
              ></ListItem>

              <ListItem
                sx={{
                  borderBottom: '1px dashed #ccc',
                }}
                disablePadding
                onClick={() => {
                  Navigate('/');
                  handleDrawerClose();
                }}
              >
                <ListItemButton
                  sx={{
                    textAlign: 'left',
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: '34px',
                    }}
                  >
                    <BiBarcodeReader fontSize={20} color='#ccc' />
                  </ListItemIcon>
                  <ListItemText primary='Scan QR' />

                  <ListItemIcon
                    sx={{
                      display: 'flex',
                      justifyContent: 'end',
                      color: '#ccc',
                      fontWeight: '500',
                    }}
                  >
                    <BiChevronsRight fontSize={20} />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
              {/* <ListItem
                sx={{
                  borderBottom: '1px dashed #ccc',
                }}
                disablePadding
                onClick={() => {
                  Navigate('/multiple-scan');
                  handleDrawerClose();
                }}
                // onClick={handleClick}
              >
                <ListItemButton
                  sx={{
                    textAlign: 'left',
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: '34px',
                    }}
                  >
                    <BiBarcodeReader fontSize={20} color='#ccc' />
                  </ListItemIcon>
                  <ListItemText primary='Multiple Scan QR ' />

                  <ListItemIcon
                    sx={{
                      display: 'flex',
                      justifyContent: 'end',
                      color: '#ccc',
                      fontWeight: '500',
                    }}
                  >
                    <BiChevronsRight fontSize={20} />
                  </ListItemIcon>
                </ListItemButton>
                  </ListItem> */}
              <ListItem
                sx={{
                  borderBottom: '1px dashed #ccc',
                }}
                disablePadding
                onClick={() => {
                  Navigate('/Inspection-list');
                  handleDrawerClose();
                }}
              >
                <ListItemButton
                  sx={{
                    textAlign: 'left',
                    width: '500px',
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: '34px',
                    }}
                  >
                    <FaClipboardList fontSize={18} color='#ccc' />
                  </ListItemIcon>
                  <ListItemText primary='Inspection List' />

                  <ListItemIcon
                    sx={{
                      display: 'flex',
                      justifyContent: 'end',
                      color: '#ccc',
                      fontWeight: '500',
                    }}
                  >
                    <BiChevronsRight fontSize={20} />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
              <ListItem
                sx={{
                  borderBottom: '1px dashed #ccc',
                }}
                disablePadding
                onClick={() => {
                  Navigate('/contact-us');
                  handleDrawerClose();
                }}
              >
                <ListItemButton
                  sx={{
                    textAlign: 'left',
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: '34px',
                    }}
                  >
                    <BiGroup fontSize={20} color='#ccc' />
                  </ListItemIcon>
                  <ListItemText primary='Contact Us' />

                  <ListItemIcon
                    sx={{
                      display: 'flex',
                      justifyContent: 'end',
                      color: '#ccc',
                      fontWeight: '500',
                    }}
                  >
                    <BiChevronsRight fontSize={20} />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>

              <ListItem
                sx={{
                  borderBottom: '1px dashed #ccc',
                }}
                disablePadding
                onClick={() => onLogoutHandler()}
              >
                <ListItemButton
                  sx={{
                    textAlign: 'left',
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: '34px',
                    }}
                  >
                    <BiLogOut fontSize={20} color='#ccc' />
                  </ListItemIcon>
                  <ListItemText primary='Log out' />

                  <ListItemIcon
                    sx={{
                      display: 'flex',
                      justifyContent: 'end',
                      color: '#ccc',
                      fontWeight: '500',
                    }}
                  >
                    <BiChevronsRight fontSize={20} />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            </>
          ) : null}
        </List>
        <Typography
          variant='p'
          sx={{
            position: 'absolute',
            bottom: '0px',
            left: '50%',
            transform: 'translateX(-50%)',
            color: '#ccc',
          }}
        >
          Version 1.0
        </Typography>
      </Drawer>
      <Box open={open}>
        <DrawerHeader />
      </Box>
    </Box>
  );
}
