import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Grid,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { InspectorDetailsAPI } from '../../http/server-apis';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { BiChevronUp } from 'react-icons/bi';
import { AiOutlineWarning } from 'react-icons/ai';
import ScrollTop from '../../components/ScrollTop';
import { useSelector } from 'react-redux';
import { IoArrowBackCircle } from 'react-icons/io5';

const getStatusText = (status) => {
  switch (status) {
    case 0:
      return 'Defective';
    case 1:
      return 'Checked';
    default:
      return '-';
  }
};

export default function TotalScanList() {
  const { InspectorID } = useSelector((state) => state.User);
  const [inspectionData, setInspectionData] = useState([]);
  const [noDataMessage, setNoDataMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const Navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await InspectorDetailsAPI('get', {
          postfix: `/id?InspectorID=${InspectorID}&GUID=${id}`,
        });

        const responseData = res.data;

        if (responseData.length === 0) {
          setNoDataMessage('No data found');
        } else {
          setNoDataMessage('');
        }

        setInspectionData(responseData);
      } catch (error) {
        console.error(
          'Error fetching data:',
          error.response?.data || error.message
        );
        setNoDataMessage('Error fetching data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [InspectorID, id]);

  const backgroundColor = (status) => {
    switch (status) {
      case 0:
        return '#c10100';
      case 2:
        return '#8d8787';
      case 1:
      case 3:
        return '#28a400';
      default:
        return '-';
    }
  };

  if (loading) {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        height='100vh'
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box
        padding='10px 3px'
        backgroundColor='#f5f5f5'
        width='100%'
        zIndex={30}
        className='bread-title'
      >
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Typography
              variant='h6'
              sx={{
                fontFamily: 'Lato, sans-serif',
                fontWeight: 600,
              }}
            >
              Total Scan List
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button
              style={{
                padding: '6px 23px',
                backgroundColor: '#333',
                fontSize: '1rem',
                color: '#fff',
                fontWeight: 600,
                textTransform: 'capitalize',
              }}
              onClick={() => Navigate(-1)}
            >
              Back
            </Button>
          </Grid>
        </Grid>
      </Box>
      {noDataMessage && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '4px',
            height: '70vh',
            textAlign: 'center',
          }}
        >
          <Typography>
            <AiOutlineWarning
              style={{
                width: '50px',
                height: '50px',
                marginBottom: '10px',
                color: '#ccc',
              }}
            />
          </Typography>

          <Typography variant='h5' color='gray'>
            {noDataMessage}
          </Typography>
        </Box>
      )}

      <Box sx={{ padding: '10px' }}>
        {inspectionData.map((row, i) => (
          <Card
            key={i}
            sx={{
              border: '1px solid #ccc',
              borderBottom: '3px solid #fec107',
              marginBottom: '30px',
              boxShadow: 'none',
              borderRadius: '10px',
            }}
          >
            <List>
              <ListItem
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Typography
                  variant='p'
                  color='#9e9e9e'
                  sx={{
                    flexBasis: '35%',
                    fontSize: '0.8rem',
                    fontFamily: 'Lato, sans-serif',
                    fontWeight: 400,
                  }}
                >
                  Batch No
                </Typography>
                <Typography
                  variant='p'
                  sx={{
                    flexBasis: '5%',
                    fontSize: '0.9rem',
                    fontFamily: 'Lato, sans-serif',
                    fontWeight: 600,
                  }}
                >
                  :
                </Typography>
                <Typography
                  variant='p'
                  sx={{
                    flexBasis: '60%',
                    fontSize: '0.9rem',
                    fontFamily: 'Lato, sans-serif',
                    fontWeight: 600,
                  }}
                >
                  {row?.BatchNo}
                </Typography>

                <Chip
                  label={getStatusText(row.Status)}
                  sx={{
                    backgroundColor: backgroundColor(row.Status),
                    width: '28%',
                    color: '#fff',
                    position: 'absolute',
                    right: '2%',
                  }}
                />
              </ListItem>

              <ListItem
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Typography
                  variant='p'
                  color='#9e9e9e'
                  sx={{
                    flexBasis: '35%',
                    fontSize: '0.8rem',
                    fontFamily: 'Lato, sans-serif',
                    fontWeight: 400,
                  }}
                >
                  Model Name
                </Typography>
                <Typography
                  variant='p'
                  sx={{
                    flexBasis: '5%',
                    fontSize: '0.9rem',
                    fontFamily: 'Lato, sans-serif',
                    fontWeight: 600,
                  }}
                >
                  :
                </Typography>
                <Typography
                  variant='p'
                  sx={{
                    flexBasis: '60%',
                    fontSize: '0.9rem',
                    fontFamily: 'Lato, sans-serif',
                    fontWeight: 600,
                  }}
                >
                  {row.Model}
                </Typography>
              </ListItem>
              <ListItem
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Typography
                  variant='p'
                  color='#9e9e9e'
                  sx={{
                    flexBasis: '35%',
                    fontSize: '0.8rem',
                    fontFamily: 'Lato, sans-serif',
                    fontWeight: 400,
                  }}
                >
                  GUID
                </Typography>
                <Typography
                  variant='p'
                  sx={{
                    flexBasis: '5%',
                    fontSize: '0.9rem',
                    fontFamily: 'Lato, sans-serif',
                    fontWeight: 600,
                  }}
                >
                  :
                </Typography>
                <Typography
                  variant='p'
                  sx={{
                    flexBasis: '60%',
                    fontSize: '0.9rem',
                    fontFamily: 'Lato, sans-serif',
                    fontWeight: 600,
                  }}
                >
                  {row.GUID}
                </Typography>
              </ListItem>

              <ListItem
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Typography
                  variant='p'
                  color='#9e9e9e'
                  sx={{
                    flexBasis: '35%',
                    fontSize: '0.8rem',
                    fontFamily: 'Lato, sans-serif',
                    fontWeight: 400,
                  }}
                >
                  Serial No
                </Typography>
                <Typography
                  variant='p'
                  sx={{
                    flexBasis: '5%',
                    fontSize: '0.9rem',
                    fontFamily: 'Lato, sans-serif',
                    fontWeight: 600,
                  }}
                >
                  :
                </Typography>
                <Typography
                  variant='p'
                  sx={{
                    flexBasis: '60%',
                    fontSize: '0.9rem',
                    fontFamily: 'Lato, sans-serif',
                    fontWeight: 600,
                  }}
                >
                  {row?.GUID && row.GUID.split('-').pop()}
                </Typography>
              </ListItem>

              {row?.ReasonName ? (
                <ListItem
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Typography
                    variant='p'
                    color='#9e9e9e'
                    sx={{
                      flexBasis: '35%',
                      fontSize: '0.8rem',
                      fontFamily: 'Lato, sans-serif',
                      fontWeight: 400,
                    }}
                  >
                    Reason Name
                  </Typography>
                  <Typography
                    variant='p'
                    sx={{
                      flexBasis: '5%',
                      fontSize: '0.9rem',
                      fontFamily: 'Lato, sans-serif',
                      fontWeight: 600,
                    }}
                  >
                    :
                  </Typography>
                  <Typography
                    variant='p'
                    sx={{
                      flexBasis: '60%',
                      fontSize: '0.9rem',
                      fontFamily: 'Lato, sans-serif',
                      fontWeight: 600,
                      wordBreak: 'break-word',
                    }}
                  >
                    {row.ReasonName === 'Others'
                      ? row.OtherReason
                      : row.ReasonName}
                  </Typography>
                </ListItem>
              ) : (
                ''
              )}

              <ListItem
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Typography
                  variant='p'
                  color='#9e9e9e'
                  sx={{
                    flexBasis: '35%',
                    fontSize: '0.8rem',
                    fontFamily: 'Lato, sans-serif',
                    fontWeight: 400,
                  }}
                >
                  Date
                </Typography>
                <Typography
                  variant='p'
                  sx={{
                    flexBasis: '5%',
                    fontSize: '0.9rem',
                    fontFamily: 'Lato, sans-serif',
                    fontWeight: 600,
                  }}
                >
                  :
                </Typography>
                <Typography
                  variant='p'
                  sx={{
                    flexBasis: '60%',
                    fontSize: '0.9rem',
                    fontFamily: 'Lato, sans-serif',
                    fontWeight: 600,
                  }}
                >
                  {dayjs(row.DOC).format('MMM, DD YYYY')}
                </Typography>
              </ListItem>
            </List>
          </Card>
        ))}
      </Box>
      <ScrollTop>
        <BiChevronUp size={25} />
      </ScrollTop>
    </>
  );
}
