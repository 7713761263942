import { Box } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Qr from "../customer/CustomerQr";
import { UpdateDeviceInfoID } from "../../state/User";

function Home() {
  const dispatch = useDispatch();
  const params = new URLSearchParams(document.location.search);
  const DeviceInfoId = params.get("id") || params.get("DeviceInfoID") || 1;

  useEffect(() => {
    if (DeviceInfoId && DeviceInfoId > 0) {
      dispatch(UpdateDeviceInfoID(DeviceInfoId));
    }
  }, [dispatch, DeviceInfoId]);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        height="calc(100vh - 100px)"
      >
        <Qr />
      </Box>
    </>
  );
}

export default Home;
