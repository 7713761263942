import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';

function ScrollTop({ children }) {
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setShowScroll(scrollPosition > 200);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: '5%',
        right: '2%',
        width: '35px',
        height: '35px',
        backgroundColor: showScroll ? '#fec107' : 'transparent',
        borderRadius: '50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 0px 10px #a1a1a1',
        transition: 'all 0.3s ease',
        opacity: showScroll ? 1 : 0,
        pointerEvents: showScroll ? 'auto' : 'none',
      }}
      onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
    >
      {children}
    </Box>
  );
}

export default ScrollTop;
