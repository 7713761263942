import { createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';

const MAX_SCANNED_PRODUCTS = 20;

const multipleInspectionSlice = createSlice({
  name: 'multipleInspection',
  initialState: {
    scannedProducts: [],
  },
  reducers: {
    addScannedProduct: (state, action) => {
      if (state.scannedProducts.length < MAX_SCANNED_PRODUCTS) {
        state.scannedProducts = [action.payload, ...state.scannedProducts];
        toast.success(`Successfully Added ${action.payload}`, {
          duration: 3000,
        });
      } else {
        toast.error('Scanned product limit reached.', {
          duration: 3000,
        });
      }
    },
    deleteScanData: (state, action) => {
      state.scannedProducts = state.scannedProducts.filter(
        (_, index) => index !== action.payload
      );
    },
    clearScannedProducts: (state) => {
      state.scannedProducts = [];
    },
  },
});

export const { addScannedProduct, deleteScanData, clearScannedProducts } =
  multipleInspectionSlice.actions;
export const selectScannedProducts = (state) =>
  state.multipleInspection.scannedProducts;

export default multipleInspectionSlice.reducer;
