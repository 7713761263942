import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  guid: [],
  success: false,
  error: null,
};

const storeGuid = createSlice({
  name: 'GuidArray',
  initialState,
  reducers: {
    setGuid: (state, action) => {
      state.guid = [action.payload, ...state.guid];
      state.success = true;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setAutoClear: (state) => {
      state.guid = [];
      state.success = false;
      state.error = null;
    },
  },
});

export const { setGuid, setError, setAutoClear } = storeGuid.actions;

export default storeGuid.reducer;
