import React from "react";
import { Toaster } from "react-hot-toast";

const MainLayout = ({ children }) => {
  return (
    <div className="App">
        <Toaster position="top-right" />
        {children}
    </div>
  );
};

export default MainLayout;
