import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  List,
  ListItem,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { InspectorDetailsAPI } from '../../http/server-apis';
import dayjs from 'dayjs';
import { BiChevronUp } from 'react-icons/bi';
import { AiOutlineWarning } from 'react-icons/ai';
import ScrollTop from '../../components/ScrollTop';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const getStatusText = (status) => {
  switch (status) {
    case 0:
      return 'Defective';
    case 1:
      return 'Checked';
    default:
      return '-';
  }
};

const MonthArray = [
  { title: 'Jan', id: 1 },
  { title: 'Feb', id: 2 },
  { title: 'Mar', id: 3 },
  { title: 'Apr', id: 4 },
  { title: 'May', id: 5 },
  { title: 'Jun', id: 6 },
  { title: 'Jul', id: 7 },
  { title: 'Aug', id: 8 },
  { title: 'Sep', id: 9 },
  { title: 'Oct', id: 10 },
  { title: 'Nov', id: 11 },
  { title: 'Dec', id: 12 },
];

const YearArray = [2024, 2023, 2022];

export default function InspectionList() {
  const { InspectorID } = useSelector((state) => state.User);
  const [inspectionData, setInspectionData] = useState([]);
  const [dayFilter, setDayFilter] = useState('30days');
  const [monthFilter, setMonthFilter] = useState(new Date().getMonth() + 1);
  const [yearFilter, setYearFilter] = useState(new Date().getFullYear());
  const [noDataMessage, setNoDataMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const Navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const paddedMonth = String(monthFilter).padStart(2, '0');

        const res = await InspectorDetailsAPI('get', {
          postfix: `/all?InspectorID=${InspectorID}&Days=${dayFilter}&Month=${paddedMonth}&Year=${yearFilter}`,
        });

        const responseData = res.data;

        if (responseData.length === 0) {
          setNoDataMessage('No data found');
        } else {
          setNoDataMessage('');
        }

        setInspectionData(responseData);
      } catch (error) {
        console.error(
          'Error fetching data:',
          error.response?.data || error.message
        );
        setNoDataMessage('Error fetching data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dayFilter, monthFilter, yearFilter, InspectorID]);

  const backgroundColor = (status) => {
    switch (status) {
      case 0:
        return '#c10100';
      case 2:
        return '#8d8787';
      case 1:
      case 3:
        return '#28a400';
      default:
        return '-';
    }
  };
  const handleNavigate = (id) => {
    Navigate(`/total-scan-list/${id}`);
  };

  if (loading) {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        height='100vh'
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box
        padding='10px 3px'
        backgroundColor='#f5f5f5'
        width='100%'
        zIndex={30}
        className='bread-title'
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography
              variant='h6'
              sx={{
                fontFamily: 'Lato, sans-serif',
                fontWeight: 600,
              }}
            >
              Inspection List
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box
              display='flex'
              justifyContent='space-between'
              px='10px'
              gap={1}
            >
              <FormControl size='small'>
                <Select
                  value={dayFilter}
                  onChange={(e) => setDayFilter(e.target.value)}
                >
                  <MenuItem value='today'>Today</MenuItem>
                  <MenuItem value='7days'>7 Days</MenuItem>
                  <MenuItem value='30days'>30 Days</MenuItem>
                </Select>
              </FormControl>

              <FormControl size='small'>
                <Select
                  value={monthFilter}
                  onChange={(e) => setMonthFilter(e.target.value)}
                >
                  {MonthArray.map((month) => (
                    <MenuItem key={month.id} value={month.id}>
                      {month.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl size='small'>
                <Select
                  value={yearFilter}
                  onChange={(e) => setYearFilter(e.target.value)}
                >
                  {YearArray.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {noDataMessage && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '4px',
            height: '70vh',
            textAlign: 'center',
          }}
        >
          <Typography>
            <AiOutlineWarning
              style={{
                width: '50px',
                height: '50px',
                marginBottom: '10px',
                color: '#ccc',
              }}
            />
          </Typography>

          <Typography variant='h5' color='gray'>
            {noDataMessage}
          </Typography>
        </Box>
      )}

      <Box sx={{ padding: '10px' }}>
        {inspectionData.map((row, i) => (
          <Card
            key={i}
            sx={{
              border: '1px solid #ccc',
              borderBottom: '3px solid #fec107',
              marginBottom: '30px',
              boxShadow: 'none',
              borderRadius: '10px',
            }}
          >
            <List>
              <ListItem
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Typography
                  variant='p'
                  color='#9e9e9e'
                  sx={{
                    flexBasis: '35%',
                    fontSize: '0.8rem',
                    fontFamily: 'Lato, sans-serif',
                    fontWeight: 400,
                  }}
                >
                  Batch No
                </Typography>
                <Typography
                  variant='p'
                  sx={{
                    flexBasis: '5%',
                    fontSize: '0.9rem',
                    fontFamily: 'Lato, sans-serif',
                    fontWeight: 600,
                  }}
                >
                  :
                </Typography>
                <Typography
                  variant='p'
                  sx={{
                    flexBasis: '60%',
                    fontSize: '0.9rem',
                    fontFamily: 'Lato, sans-serif',
                    fontWeight: 600,
                  }}
                >
                  {row?.BatchNo}
                </Typography>

                <Chip
                  label={getStatusText(row.Status)}
                  sx={{
                    backgroundColor: backgroundColor(row.Status),
                    width: '28%',
                    color: '#fff',
                    position: 'absolute',
                    right: '2%',
                  }}
                />
              </ListItem>

              <ListItem
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Typography
                  variant='p'
                  color='#9e9e9e'
                  sx={{
                    flexBasis: '35%',
                    fontSize: '0.8rem',
                    fontFamily: 'Lato, sans-serif',
                    fontWeight: 400,
                  }}
                >
                  Model Name
                </Typography>
                <Typography
                  variant='p'
                  sx={{
                    flexBasis: '5%',
                    fontSize: '0.9rem',
                    fontFamily: 'Lato, sans-serif',
                    fontWeight: 600,
                  }}
                >
                  :
                </Typography>
                <Typography
                  variant='p'
                  sx={{
                    flexBasis: '60%',
                    fontSize: '0.9rem',
                    fontFamily: 'Lato, sans-serif',
                    fontWeight: 600,
                  }}
                >
                  {row.Model}
                </Typography>
              </ListItem>
              <ListItem
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Typography
                  variant='p'
                  color='#9e9e9e'
                  sx={{
                    flexBasis: '35%',
                    fontSize: '0.8rem',
                    fontFamily: 'Lato, sans-serif',
                    fontWeight: 400,
                  }}
                >
                  GUID
                </Typography>
                <Typography
                  variant='p'
                  sx={{
                    flexBasis: '5%',
                    fontSize: '0.9rem',
                    fontFamily: 'Lato, sans-serif',
                    fontWeight: 600,
                  }}
                >
                  :
                </Typography>
                <Typography
                  variant='p'
                  sx={{
                    flexBasis: '60%',
                    fontSize: '0.9rem',
                    fontFamily: 'Lato, sans-serif',
                    fontWeight: 600,
                  }}
                >
                  {row.GUID}
                </Typography>
              </ListItem>

              <ListItem
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Typography
                  variant='p'
                  color='#9e9e9e'
                  sx={{
                    flexBasis: '35%',
                    fontSize: '0.8rem',
                    fontFamily: 'Lato, sans-serif',
                    fontWeight: 400,
                  }}
                >
                  Serial No
                </Typography>
                <Typography
                  variant='p'
                  sx={{
                    flexBasis: '5%',
                    fontSize: '0.9rem',
                    fontFamily: 'Lato, sans-serif',
                    fontWeight: 600,
                  }}
                >
                  :
                </Typography>
                <Typography
                  variant='p'
                  sx={{
                    flexBasis: '60%',
                    fontSize: '0.9rem',
                    fontFamily: 'Lato, sans-serif',
                    fontWeight: 600,
                  }}
                >
                  {row?.GUID && row.GUID.split('-').pop()}
                </Typography>
              </ListItem>

              {row?.ReasonName ? (
                <ListItem
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Typography
                    variant='p'
                    color='#9e9e9e'
                    sx={{
                      flexBasis: '35%',
                      fontSize: '0.8rem',
                      fontFamily: 'Lato, sans-serif',
                      fontWeight: 400,
                    }}
                  >
                    Reason Name
                  </Typography>
                  <Typography
                    variant='p'
                    sx={{
                      flexBasis: '5%',
                      fontSize: '0.9rem',
                      fontFamily: 'Lato, sans-serif',
                      fontWeight: 600,
                    }}
                  >
                    :
                  </Typography>
                  <Typography
                    variant='p'
                    sx={{
                      flexBasis: '60%',
                      fontSize: '0.9rem',
                      fontFamily: 'Lato, sans-serif',
                      fontWeight: 600,
                      wordBreak: 'break-word',
                    }}
                  >
                    {row.ReasonName === 'Others'
                      ? row.OtherReason
                      : row.ReasonName}
                  </Typography>
                </ListItem>
              ) : (
                ''
              )}

              <ListItem
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Typography
                  variant='p'
                  color='#9e9e9e'
                  sx={{
                    flexBasis: '35%',
                    fontSize: '0.8rem',
                    fontFamily: 'Lato, sans-serif',
                    fontWeight: 400,
                  }}
                >
                  Date
                </Typography>
                <Typography
                  variant='p'
                  sx={{
                    flexBasis: '5%',
                    fontSize: '0.9rem',
                    fontFamily: 'Lato, sans-serif',
                    fontWeight: 600,
                  }}
                >
                  :
                </Typography>
                <Typography
                  variant='p'
                  sx={{
                    flexBasis: '60%',
                    fontSize: '0.9rem',
                    fontFamily: 'Lato, sans-serif',
                    fontWeight: 600,
                  }}
                >
                  {dayjs(row.DOC).format('MMM, DD YYYY')}
                </Typography>
              </ListItem>
              <ListItem
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Typography
                  variant='p'
                  color='#9e9e9e'
                  sx={{
                    flexBasis: '35%',
                    fontSize: '0.8rem',
                    fontFamily: 'Lato, sans-serif',
                    fontWeight: 400,
                  }}
                >
                  Total Scan
                </Typography>
                <Typography
                  variant='p'
                  sx={{
                    flexBasis: '5%',
                    fontSize: '0.9rem',
                    fontFamily: 'Lato, sans-serif',
                    fontWeight: 600,
                  }}
                >
                  :
                </Typography>
                <Typography
                  variant='p'
                  sx={{
                    flexBasis: '60%',
                    fontSize: '0.9rem',
                    fontFamily: 'Lato, sans-serif',
                    fontWeight: 600,
                  }}
                >
                  {row.Total}
                </Typography>

                <Button
                  sx={{
                    width: '28%',
                    color: '#fff',
                    position: 'absolute',
                    right: '2%',
                  }}
                  onClick={() => handleNavigate(row.GUID)}
                  style={{
                    textDecoration: 'underline',
                    color: '#000',
                    textTransform: 'capitalize',
                    fontSize: '15px',
                  }}
                >
                  Details
                </Button>
              </ListItem>
            </List>
          </Card>
        ))}
      </Box>
      <ScrollTop>
        <BiChevronUp size={25} />
      </ScrollTop>
    </>
  );
}
