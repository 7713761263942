import React from 'react';
import { Container, Typography, Divider, Box } from '@mui/material';
import { BiPhoneCall, BiEnvelope, BiMap } from 'react-icons/bi';
import ContactImage from '../../assets/images/contact-mail.png';

const ContactUsPage = () => {
  return (
    <Container
      maxWidth='md'
      sx={{
        marginTop: '30px',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80vh',
      }}
    >
      <img
        src={ContactImage}
        alt='contact'
        style={{
          width: '20%',
          margin: '0px auto 80px auto',
          display: 'block',
        }}
      />
      <Typography
        variant='h6'
        sx={{
          fontFamily: 'Lato, sans-serif',
          fontWeight: 600,
        }}
      >
        Contact Us
      </Typography>

      <Box
        mb={4}
        sx={{
          lineHeight: '3',
          fontWeight: 'bold',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography
          variant='subtitle1'
          sx={{
            fontWeight: '500',
            color: '#333',
            fontFamily: 'Lato, sans-serif',
            fontSize: '1rem',
          }}
        >
          Office Hours: Mon – Sat: 10:30AM – 6:30PM
        </Typography>
        <Typography
          variant='subtitle1'
          sx={{
            fontWeight: '500',
            color: '#333',
            fontFamily: 'Lato, sans-serif',
            fontSize: '1rem',
          }}
        >
          <BiPhoneCall color='#fec107' /> +91 9163368067
        </Typography>
        <Typography
          variant='subtitle1'
          sx={{
            fontWeight: '500',
            color: '#333',
            fontFamily: 'Lato, sans-serif',
            fontSize: '1rem',
          }}
        >
          <BiEnvelope color='#fec107' /> care@udyogi.net
        </Typography>
        <Typography
          variant='subtitle1'
          sx={{
            fontWeight: '500',
            color: '#333',
            fontFamily: 'Lato, sans-serif',
            fontSize: '1rem',
          }}
        >
          <BiMap color='#fec107' /> 294, B B Ganguly Street
          <br />
          2nd Floor, Kolkata-700 012
        </Typography>
      </Box>
      <Divider />
    </Container>
  );
};

export default ContactUsPage;
