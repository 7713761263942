import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import QrReader from 'react-qr-scanner';
import { BiQrScan } from 'react-icons/bi';
import { ScannerApi } from '../../http/server-apis';
import { useSelector, useDispatch } from 'react-redux';

import toast from 'react-hot-toast';
import { addScannedProduct } from '../../state/multipleInspectionSlice';

const defaultStyle = {
  height: 400,
  width: 350,
};

const MultipleQrScanner = ({ previewStyle }) => {
  const [data, setData] = useState(null);
  const { scannedProducts } = useSelector((state) => state.multipleInspection);

  const [refresh, setRefresh] = useState(1);
  const { DeviceInfoId } = useSelector((state) => state.User);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);

      const headers = {
        Apikey: 123456,
      };
      const params_data = {
        ID: Number(DeviceInfoId),
        GUID: data?.text,
      };

      try {
        if (scannedProducts.includes(data?.text)) {
          return toast.error('QR code can not be same!', {
            duration: 3000,
          });
        } else {
          const response = await ScannerApi('post', {
            data: params_data,
            headers: headers,
          });

          if (response?.data?.status === 'success') {
            dispatch(addScannedProduct(data?.text));

            setData(null);
            setError('');
          } else {
            toast.error('QR code not found!', {
              duration: 3000,
            });
          }
        }
      } catch (error) {
        if (error.response) {
          setError(`Server error: ${error.response.data.message}`);
        } else {
          setError('There was an error fetching data from the server.');
        }
      } finally {
        setIsLoading(false);
      }
    };

    if (data?.text) {
      getData();
    }
  }, [DeviceInfoId, data?.text, refresh]);

  useEffect(() => {}, [refresh]);

  const handleScan = React.useCallback(
    (scannedData) => {
      if (scannedData?.text) {
        setData(scannedData);
        setError('');
      }
    },
    [refresh]
  );

  const handleError = () => {
    setError('Error scanning QR code. Please try again.');
    setError(`Server error: ${error.response.data.message}`);
  };

  return (
    <Grid container>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        padding='10px 10px'
        backgroundColor='#f5f5f5'
        position='fixed'
        top='7%'
        left='0%'
        width='100%'
        className='bread-title'
      >
        <Typography
          variant='h6'
          sx={{
            fontFamily: 'Lato, sans-serif',
            fontWeight: 600,
          }}
        >
          Multiple Inspection Dashboard
        </Typography>
      </Box>
      <Grid item xs={12} px='20px'>
        <Grid
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          mt={'20px'}
        >
          <QrReader
            resolution={600}
            style={previewStyle || defaultStyle}
            onError={handleError}
            onScan={handleScan}
            constraints={{
              video: { facingMode: 'environment' },
            }}
          />

          <Typography
            sx={{
              fontSize: '0.9rem',
              color: '#8d8787',
              fontFamily: 'Lato, sans-serif',
              fontWeight: 600,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <BiQrScan
              style={{ width: '20px', height: '20px', marginRight: '5px' }}
            />
            Scan QR code
          </Typography>
        </Grid>

        {!isLoading && error && (
          <>
            <Typography
              sx={{
                fontSize: '1rem',
                color: 'red',
                textAlign: 'center',
                marginTop: '10px',
              }}
            >
              {error}
            </Typography>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default MultipleQrScanner;
