import React from 'react';
import { Box } from '@mui/material';

import AdgLogo from '../../assets/images/adg_logo.svg';
import FireImg from '../../assets/images/fire.svg';
import EnIcon from '../../assets/images/en-icon.svg';
import OekaImg from '../../assets/images/oeko.svg';
import NfpaImg from '../../assets/images/nfpa.svg';
import InstructionImg from '../../assets/images/ins.svg';
const SeccondRes = ({ data }) => {
  const {
    BatchNo,
    ProductName,
    OZ,
    ManufacturingDate,
    FabricWeight,
    Fabric,
    ShortCode,
  } = data;
  return (
    <Box>
      <img
        src={AdgLogo}
        alt='adg'
        style={{
          width: '80%',
          margin: '50px auto',
          display: 'block',
        }}
      />
      <ul
        style={{
          padding: '10px 20px',
        }}
      >
        <li
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '10px',
            border: '1px solid black',
            borderRight: 0,
            borderLeft: 0,
            backgroundColor: '#e5e5e5',
          }}
        >
          <p
            style={{
              display: 'flex',
              flexBasis: '40%',
              fontSize: '14px',
            }}
          >
            Modal
          </p>
          <p
            style={{
              display: 'flex',
              flexBasis: '60%',
              fontSize: '14px',
            }}
          >
            {ShortCode}
          </p>
        </li>

        <li
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '10px',

            backgroundColor: '#fff',
          }}
        >
          <p
            style={{
              display: 'flex',
              flexBasis: '40%',
              fontSize: '14px',
            }}
          >
            Style
          </p>
          <p
            style={{
              display: 'flex',
              flexBasis: '60%',
              fontSize: '14px',
            }}
          >
            {ProductName}
          </p>
        </li>

        <li
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '10px',
            borderTop: '1px solid #000',
            borderBottom: '1px solid #000',
            backgroundColor: '#e5e5e5',
          }}
        >
          <p
            style={{
              display: 'flex',
              flexBasis: '40%',
              fontSize: '14px',
            }}
          >
            Fabric Weight
          </p>
          <p
            style={{
              display: 'flex',
              flexBasis: '60%',
              fontSize: '14px',
            }}
          >
            {FabricWeight}
          </p>
        </li>

        <li
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '10px',
            borderBottom: '1px solid #000',
            backgroundColor: '#fff',
          }}
        >
          <p
            style={{
              display: 'flex',
              flexBasis: '40%',
              fontSize: '14px',
            }}
          >
            OZ
          </p>
          <p
            style={{
              display: 'flex',
              flexBasis: '60%',
              fontSize: '14px',
            }}
          >
            {OZ}
          </p>
        </li>

        <li
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '10px',
            borderBottom: '1px solid #000',
            backgroundColor: '#e5e5e5',
          }}
        >
          <p
            style={{
              display: 'flex',
              flexBasis: '40%',
              fontSize: '14px',
            }}
          >
            Fabric
          </p>
          <p
            style={{
              display: 'flex',
              flexBasis: '60%',
              fontSize: '14px',
            }}
          >
            {Fabric}
          </p>
        </li>
      </ul>

      <Box
        style={{
          padding: '10px 20px',
        }}
      >
        <h4>Meets:</h4>

        <ul
          style={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          <li
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                width: '20%',
              }}
            >
              <img
                src={FireImg}
                alt='fire'
                style={{
                  width: '100%',
                }}
              />
            </div>

            <p
              style={{
                display: 'flex',
                flexBasis: '70%',
                fontSize: '12px',
                fontWeight: 'bold',
              }}
            >
              EN ISO 11612:2015 A1 A2 B1 C1 E3 F2
            </p>
          </li>

          <li
            style={{
              display: 'flex',
              alignItems: 'center',
              flexBasis: '60%',
            }}
          >
            <div
              style={{
                width: '35%',
              }}
            >
              <img
                src={EnIcon}
                alt='fire'
                style={{
                  width: '100%',
                }}
              />
            </div>

            <p
              style={{
                display: 'flex',
                flexBasis: '70%',
                fontSize: '12px',
                fontWeight: 'bold',
              }}
            >
              EN 1149-5:2018
            </p>
          </li>

          <li
            style={{
              display: 'flex',
              alignItems: 'center',
              flexBasis: '40%',
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'end',
              }}
            >
              <img
                src={OekaImg}
                alt='fire'
                style={{
                  width: '95%',
                }}
              />
            </div>
          </li>

          <li
            style={{
              display: 'flex',
              alignItems: 'center',
              flexBasis: '60%',
            }}
          >
            <div
              style={{
                width: '34.9%',
              }}
            >
              <img
                src={NfpaImg}
                alt='fire'
                style={{
                  width: '100%',
                }}
              />
            </div>

            <div className='d-flex flex-column align-items-start'>
              <p
                style={{
                  fontSize: '12px',
                  fontWeight: 'bold',
                }}
              >
                70E CAT II
              </p>
              <p
                style={{
                  fontSize: '12px',
                  fontWeight: 'bold',
                }}
              >
                ATPV Rating 24 Cal/cm<sup>2</sup>{' '}
              </p>
            </div>
          </li>
          <li
            style={{
              display: 'flex',
              alignItems: 'center',
              flexBasis: '40%',
            }}
          >
            <div
              style={{
                width: '50%',
              }}
            >
              <img
                src={NfpaImg}
                alt='fire'
                style={{
                  width: '100%',
                }}
              />
            </div>

            <div className='d-flex flex-column align-items-start'>
              <p
                style={{
                  fontSize: '12px',
                  fontWeight: 'bold',
                }}
              >
                2112{' '}
              </p>
            </div>
          </li>
        </ul>
      </Box>

      <Box
        style={{
          borderTop: '1px solid #000',
          borderBottom: '1px solid #000',
          padding: '10px 20px',
        }}
      >
        <p
          style={{
            fontSize: '12px',
            fontWeight: 'bold',
            marginBottom: '10px',
          }}
        >
          Batch number : {BatchNo}
        </p>
        <p
          style={{
            fontSize: '12px',
            fontWeight: 'bold',
          }}
        >
          Month & Year of Manufacturing : {ManufacturingDate}
        </p>
      </Box>

      <Box
        style={{
          borderTop: '1px solid #000',
          borderBottom: '1px solid #000',
          padding: '10px 20px',
          backgroundColor: '#e5e5e5',
        }}
      >
        <h4> Recommendations:</h4>

        <ol
          style={{
            padding: '10px 0px',
            display: 'flex',
            flexDirection: 'column',
            gap: '0.2rem',
          }}
        >
          <li
            style={{
              fontSize: '12px',
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                fontSize: '20px',
                fontWeight: 'bold',
                width: '7%',
              }}
            >
              &#8226;
            </p>
            <p
              style={{
                display: 'flex',
                flex: 1,
              }}
            >
              It is the responsibility of this garment's user to examine its
              condition before and after each use to be sure that it is suitable
              for use.
            </p>
          </li>

          <li
            style={{
              fontSize: '12px',
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                fontSize: '20px',
                fontWeight: 'bold',
                width: '7%',
              }}
            >
              &#8226;
            </p>
            <p
              style={{
                display: 'flex',
                flex: 1,
              }}
            >
              Wash new garment before wearing to remove fabric processing aids
              and finishes.
            </p>
          </li>

          <li
            style={{
              fontSize: '12px',
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                fontSize: '20px',
                fontWeight: 'bold',
                width: '7%',
              }}
            >
              &#8226;
            </p>
            <p
              style={{
                display: 'flex',
                flex: 1,
              }}
            >
              Do not wear damaged or contaminated garments.
            </p>
          </li>

          <li
            style={{
              fontSize: '12px',
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                fontSize: '20px',
                fontWeight: 'bold',
                width: '7%',
              }}
            >
              &#8226;
            </p>
            <p
              style={{
                display: 'flex',
                flex: 1,
              }}
            >
              Do not wear flammable or meltable garments over this garment.
            </p>
          </li>

          <li
            style={{
              fontSize: '12px',
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                fontSize: '20px',
                fontWeight: 'bold',
                width: '7%',
              }}
            >
              &#8226;
            </p>
            <p
              style={{
                display: 'flex',
                flex: 1,
              }}
            >
              For flash fire hazards, wear flame-resistant undergarments.
            </p>
          </li>

          <li
            style={{
              fontSize: '12px',
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                fontSize: '20px',
                fontWeight: 'bold',
                width: '7%',
              }}
            >
              &#8226;
            </p>
            <p
              style={{
                display: 'flex',
                flex: 1,
              }}
            >
              Garment should be loose fitting and provide adequate mobility for
              the intended use.
            </p>
          </li>

          <li
            style={{
              fontSize: '12px',
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                fontSize: '20px',
                fontWeight: 'bold',
                width: '7%',
              }}
            >
              &#8226;
            </p>
            <p
              style={{
                display: 'flex',
                flex: 1,
              }}
            >
              All closures must be fully secured.
            </p>
          </li>
        </ol>
      </Box>

      <Box padding='10px 20px'>
        <h4
          style={{
            textDecoration: 'underline',
            textAlign: 'center',
            color: 'black',
            marginBottom: '20px',
          }}
        >
          CAUTION: READ BEFORE USE
        </h4>
        <p
          style={{
            fontSize: '12px',
            fontWeight: 'bold',
            textAlign: 'justify',
          }}
        >
          Flammable contaminants will reduce the thermal protection of any flame
          retardant garment. Wash this garment frequently to ensure that no
          greases, ov sailc or othpr flammable contaminants are present when the
          garment is worn. Repairs or alterations to the garment must be made
          with flame resistant components only.
        </p>{' '}
      </Box>

      <Box padding='10px 20px'>
        <h4
          style={{
            textDecoration: 'underline',
            textAlign: 'center',
            color: 'black',
            marginBottom: '20px',
          }}
        >
          WASHING INSTRUCTIONS
        </h4>

        <img
          src={InstructionImg}
          alt='instrucations'
          style={{
            width: '100%',
          }}
        />
        <Box>
          <p className='d-flex items-center gap-2 f-12 font-bold'>
            <span className='dot' /> Wash at maximum 60°C
          </p>

          <p className='d-flex items-center gap-2 f-12 font-bold'>
            <span className='dot' /> Never use chlorine bleach
          </p>

          <p className='d-flex items-center gap-2 f-12 font-bold'>
            <span className='dot' /> Use liquid detergents only
          </p>

          <p className='d-flex items-center gap-2 f-12 font-bold'>
            <span className='dot' /> Do not iron
          </p>

          <p className='d-flex items-center gap-2 f-12 font-bold'>
            <span className='dot' /> Do not dry clean
          </p>

          <p className='d-flex items-center gap-2 f-12 font-bold'>
            <span className='dot' /> Tumble dry low heat
          </p>

          <p className='d-flex items-center gap-2 f-12 font-bold'>
            <span className='dot' /> Wash in an industrial washer
          </p>
        </Box>
      </Box>

      <Box padding='10px 20px'>
        <p className='f-12 font-bold mb-2'>Manufactured &amp; Marketed by : </p>
        <img
          src='https://qr.udyogisafety.com/product/Image/udyogi-logo.svg'
          alt='udyogi-logo'
          style={{ width: '50%' }}
        />
        <p className='f-12 font-bold'>Udyogi Plastics Pvt. Ltd.</p>
        <p className='f-12 font-bold'>294, B. B. Ganguly Street, 2nd Floor,</p>
        <p className='f-12 font-bold'>Kolkata - 700012, India</p>
        <p className='f-12 font-bold'>E-mail : info@udyogi.net</p>
        <p className='f-12 font-bold'>www.udyogisafety.com</p>
      </Box>
    </Box>
  );
};
export default SeccondRes;
