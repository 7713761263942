import {
  Button,
  Box,
  TextField,
  InputLabel,
  Typography,
  IconButton,
  Grid,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { LoginApi } from '../../http/server-apis';
import { Login } from '../../state/User';
import { useDispatch, useSelector } from 'react-redux';
import loginImage from '../../assets/images/lock.png';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import dayjs from 'dayjs';
// require the isBetween and customParseFormat plugins
var isBetween = require('dayjs/plugin/isBetween');
var customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(isBetween);
dayjs.extend(customParseFormat);

// true or false

const InspectionLogin = () => {
  const { Login: isLogin } = useSelector((state) => state.User);

  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [disable, setDisable] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  function isValidShift(from, to) {
    let currentTime = dayjs();
    let startTime = dayjs(from, 'hh:mm A');
    let endTime = dayjs(to, 'hh:mm A');
    let result = currentTime.isBetween(startTime, endTime, 'minute');
    return result;
  }

  const onLoginHandler = async (e) => {
    e.preventDefault();
    setDisable(true);
    if (userName.length === 0 || password.length === 0) {
      toast.error('Please enter Username or Password');
      setDisable(false);
      return;
    }

    const params_data = {
      Username: userName,
      Password: password,
    };

    try {
      const response = await LoginApi('post', {
        data: params_data,
      });

      const data2 = response?.data;
      if (response.status === 200 && data2.InspectorID) {
        setDisable(false);
        // let isValid = isValidShift(data2.TimeFrom, data2.TimeTo);
        if (!data2?.Allowed)
          return toast.error(
            'Sorry, you are not allowed to login. Please contact admin and then try to login again',
            { duration: 10000 }
          );
        // if (!isValid)
        //   return toast.error(
        //     'Sorry, your shift is over. Please contact admin and then try to login again',
        //     { duration: 10000 }
        //   );
        dispatch(
          Login({
            InspectorID: data2?.InspectorID,
            Username: data2?.Username,
            Login: true,
            ...data2,
          })
        );
        Navigate('/inspection-home');
        toast.success('Login Successfully');
      } else {
        setDisable(false);
        toast.error('Something went wrong!');
      }
    } catch (error) {
      setDisable(false);
      toast.error('Error occurred while logging in.');
    }
  };

  const usernameHandleChange = (e) => {
    setUserName(e.target.value);
  };

  const passwordHandleChange = (e) => {
    setPassword(e.target.value);
  };
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  useEffect(() => {
    if (isLogin) {
      Navigate('/inspection-home');
    }
  }, [isLogin]);

  return (
    <Box
      container
      rowSpacing={2}
      display='flex'
      justifyContent='center'
      flexDirection='column'
      height='calc(100vh - 100px)'
    >
      <img
        src={loginImage}
        alt='Udyogi-logo'
        style={{
          width: '20%',
          margin: '0px auto',
          display: 'block',
        }}
      />
      <Typography
        variant='h6'
        mt={2}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        Login
      </Typography>
      <form
        onSubmit={onLoginHandler}
        style={{
          width: '90%',
          display: 'block',
          margin: '0px auto',
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {' '}
            <InputLabel
              form='Username'
              sx={{
                paddingLeft: '5px',
                fontFamily: 'Lato, sans-serif',
                fontWeight: 600,
              }}
            >
              Username
            </InputLabel>
            <TextField
              type='text'
              fullWidth
              size='small'
              value={userName}
              onChange={(e) => usernameHandleChange(e)}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel
              form='Password'
              sx={{
                paddingLeft: '5px',
                fontFamily: 'Lato, sans-serif',
                fontWeight: 600,
              }}
            >
              Password
            </InputLabel>
            <TextField
              type={showPassword ? 'text' : 'password'}
              fullWidth
              size='small'
              value={password}
              onChange={(e) => passwordHandleChange(e)}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={togglePasswordVisibility}
                    edge='end'
                    tabIndex='-1'
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </IconButton>
                ),
              }}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <Link
              style={{
                color: '#ff9800',
                fontWeight: 500,
                fontSize: '1.1rem',
                fontFamily: 'Lato, sans-serif',
              }}
            >
              Forgot Password ?
            </Link>
          </Grid> */}
          <Grid item xs={12} display='flex' justifyContent='center' gap={2}>
            <Button
              type='button'
              variant='contained'
              onClick={() => Navigate('/')}
              sx={{
                padding: '5px 25px',
                backgroundColor: '#333',
                fontSize: '1rem',
                fontWeight: '600',
                textTransform: 'capitalize',
                color: '#fff',
                fontFamily: 'Lato, sans-serif',
                '&:hover': {
                  backgroundColor: '#ccc;',
                  color: '#000',
                },
              }}
            >
              Cancel
            </Button>
            <Button
              type='submit'
              variant='contained'
              disabled={disable}
              sx={{
                textTransform: 'capitalize',
                backgroundColor: '#fec107',
                color: '#000',
                padding: '5px 25px',
                fontSize: '1rem',
                fontWeight: '600',
                fontFamily: 'Lato, sans-serif',
                '&:hover': {
                  backgroundColor: '#ccc;',
                  color: '#000',
                },
              }}
            >
              Login
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default InspectionLogin;
