import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Iframe from 'react-iframe';
import { Box, Button, Typography } from '@mui/material';

const IFramePreview = () => {
  const location = useLocation();
  const Navigate = useNavigate();
  let url = location?.state?.pdf;
  let title = location?.state?.title;
  return (
    <>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        padding='5px 10px'
        backgroundColor='#f5f5f5'
        top='10%'
        left='0%'
        width='100%'
      >
        <Typography variant='h6'>{title}</Typography>

        <Button
          onClick={() => Navigate(-1)}
          variant='contained'
          sx={{
            padding: '5px 25px',
            backgroundColor: '#333',
            fontSize: '0.8rem',
            fontWeight: '100',
            textTransform: 'capitalize',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#ccc;',
              color: '#000',
            },
          }}
        >
          Back
        </Button>
      </Box>
      <Iframe
        url={url}
        width='100%'
        height='1000'
        position='absolute'
        display='block'
        overflow='hidden'
        loading='loading'
      />
    </>
  );
};

export default IFramePreview;
